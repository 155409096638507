@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --background: linear-gradient(
      82.97deg,
      rgb(0, 0, 0) 0.05%,
      rgb(0, 0, 0) 50.05%
    ),
    #000000;
  --btn-primary: #000000;
  --btn-primary-gradient: linear-gradient(
    45deg,
    #bb7d00 0.05%,
    #b97c00 50%,
    #c98600 100.05%
  );
  --text-primary: #ffffff;
  --text-secondary: #ffffff;
  --card-background: #043d1c;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  min-height: 100vh;

  @media (max-width: 768px) {
    font-size: 57.5%;
  }
  @media (max-width: 576px) {
    font-size: 55%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--text-primary);
  
}

iframe {
  display: none !important;
}

a {
  text-decoration: none;
}

.mx {
  max-width: 1400px;
  margin: 0 auto;
}

.pad {
  padding: 0 5rem;

  @media (max-width: 768px) {
    padding: 0 3rem;
  }
  @media (max-width: 576px) {
    padding: 0 2rem;
  }
}

.pointer {
  cursor: pointer;
}

@for $i from 5 to 51 {
  .p-#{$i} {
    padding: $i + px;
  }
  .pt-#{$i} {
    padding-top: $i + px;
  }
  .pl-#{$i} {
    padding-left: $i + px;
  }
  .pr-#{$i} {
    padding-right: $i + px;
  }
  .pb-#{$i} {
    padding-bottom: $i + px;
  }
  .m-#{$i} {
    margin: $i + px;
  }
  .mt-#{$i} {
    margin-top: $i + px;
  }
  .ml-#{$i} {
    margin-left: $i + px;
  }
  .mr-#{$i} {
    margin-right: $i + px;
  }
  .mb-#{$i} {
    margin-bottom: $i + px;
  }
}

.color {
  &-primary {
    color: var(--secondary);
  }
}

p,
hgroup {
  &.text-primary {
    color: var(--text-primary);
  }
  &.text-secondary {
    color: var(--text-secondary);
  }
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: var(--text-primary);
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.6rem;
  color: var(--text-primary);
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  color: var(--text-primary);
}

p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  color: var(--text-secondary);
}

[data-position="flex-between"] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[data-position="flex"] {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  // -moz-appearance: textfield;
}

.loader {
  display: grid;
  place-items: center;

  &.size-100 {
    svg {
      width: 100px;
      height: 100px;
    }
  }

  svg {
    width: 32px;
    height: 32px;
    animation: rotateanimation 2s linear infinite;

    @keyframes rotateanimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.container {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
}

.packageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.packageButton {
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid #03dc4f;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(0, 255, 81, 0.3);
  }
}

.selected {
  background: linear-gradient(135deg, #4a22ff, #ff00e1);
  border: 1px solid #03dc4f;
  transform: scale(1.1);
}

.profitText {
  font-size: 16px;
  font-weight: bold;
  color: #1e00ff;
  margin-top: 10px;
}

.buyButton {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #4a22ff, #ff00e1);
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
  transition: 0.3s;

  &:hover {
    background: linear-gradient(135deg, #4a22ff, #ff00e1);
    transform: scale(1.05);
  }

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
}

.confirmationModal {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.confirmButton {
  background: #28a745;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #218838;
  }
}

.cancelButton {
  background: #03dc4f;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #5935dc;
  }
}

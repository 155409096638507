.level-details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 77, 12, 0.716);
  border-radius: 12px;
  padding: 20px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 8px rgba(1, 141, 13, 0.2);
  text-align: center;
  z-index: 1000;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: 90%;
    padding: 15px;
  }

  .level-details-popup-content {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      color: #04e700;
    }

    p {
      font-size: 1rem;
      margin: 10px 0;
      color: #f5f5f5;
    }

    button {
      margin-top: 20px;
      background: #09004e;
      color: #fff;
      border: none;
      border-radius: 6px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;
      
      &:hover {
        background: #00b31b;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
}

.slider-container {
    width: 100%;
    // height: 100vh;
    max-width: 1280px;
    max-height: 720px;
    margin: auto;
    overflow: hidden;
  
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px; // Optional rounded corners for better aesthetics
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  
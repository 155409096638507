.profile-coniner {
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  // max-width: 500px;
  margin: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0);
  text-align: center;
  color: white;
  transition: all 0.3s ease;
  border: 1px solid rgba(11, 240, 3, 0.3);

  &:hover {
    box-shadow: 0 12px 24px rgba(3, 252, 28, 0.5);
  }
}

.profile-heder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(5, 217, 51, 0.2);


.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #07d211;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profle-info {
  flex: 1;
  text-align: left;
  margin-left: 1rem;

  h4 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
  }
}
}
.edit-prfile {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background: rgba(0, 255, 4, 0.3);
  border-radius: 10px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.4);

  &:hover {
    background: rgba(26, 255, 0, 0);
    transform: scale(1.05);
  }

  svg {
    width: 16px;
    height: 16px;
    fill: white;
    margin-right: 5px;
  }

  h5 {
    font-size: 1rem;
    font-weight: bold;
  }
}

.referrer-deails {
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  .referer-info {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;

    p {
      margin: 0.3rem 0;
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.open-referals-btn {
  background: linear-gradient(45deg, #03dc4f, #2d08d2);
  padding: 0.8rem 1.5rem;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #03dc4f, #2d08d2);
    transform: scale(1.05);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-heder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(90, 5, 217, 0);
  
  }

  .profle-info {
    text-align: center;
    margin-top: 1rem;
  }
}


.referrer-details {
  text-align: center;
  justify-content: center;
  margin-top: 70px;
  animation: fadeInUp 1.4s ease both;
  font-size: 18px;
  color: #03dc4f;
  text-shadow: 0 0 5px #03dc4f, 0 0 10px #1424ff, 0 0 20px #03dc4f, 0 0 30px #4714ff;

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
.card-container2 {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  justify-content: space-between;
  border-color: #fff;
   
}

.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
border: 2px;
border-color: #fff;
}

.card2-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 2px;
border-color: #fff;
}
.referral-earnings-section {
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
}

.referral-earnings-card {
  background: rgba(255, 255, 255, 0); /* Glass effect */
  backdrop-filter: blur(10px); /* Glass effect */
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  // h5 {
  //   margin-bottom: 0.5rem;
  //   font-size: 1.2rem;
  //   font-weight: bold;
  // }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: #6828a7; /* Green button */
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      background: #482188; /* Darker green on hover */
      transform: scale(1.05);
    }
  }
}

.card2 {
  flex: 1;
  min-width: 150px;
  padding: 1.5rem;
  border-radius: 12px;
  background: #000; /* Black background */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  // Adding content with elevated effect
  box-shadow: 0 4px 10px rgba(0, 255, 30, 0.5); /* Subtle green glow */

  // Rotating neon border using pseudo-element
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 12px;
    // background: linear-gradient(90deg, #00ff00, transparent, #00ff00, transparent);
    background-size: 300% 300%; /* Enlarged gradient */
    animation: neonRotate 3s linear infinite; /* Smooth rotation */
    z-index: -1; /* Behind the card */
  }

  // Hover interaction
  &:hover {
    box-shadow: 0 6px 15px rgba(0, 255, 30, 0.7); /* Enhanced glow on hover */
    transform: scale(1.05); /* Scale effect */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
}

// Rotating border animation
@keyframes neonRotate {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}


// .card {
//   background: linear-gradient(135deg, #2c3e50, #34495e); /* Green glass effect */
//   border: 2px;
// border-color: #ffffff;
// }

// .card2 {
//   background: linear-gradient(135deg, #2c3e50, #34495e); /* Darker green glass effect */
//   border: 2px;
// border-color: #fff;
// }

.card2.image1 {
  background: linear-gradient(135deg, #2c3e50, #34495e); /* Different shade for direct */
  border: 2px;
border-color: #fff;
}

.card2.image2 {
  background: linear-gradient(135deg, #2c3e50, #34495e); /* Different shade for indirect */
  border: 2px;
border-color: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.card h5,
.card2 h5 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #03dc4f; /* Neon green */
  // border: 3px solid #39ff14;
  // text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14;
  font-family: 'Orbitron', sans-serif; /* Stylish font */
  letter-spacing: 1px; /* Add some spacing for a premium look */
  text-transform: uppercase; /* Make the text all caps for a bold effect */
}


.card p, .card2 p {
  font-size: 1.5rem;
  font-weight: 600;
}
.highlight {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: rgba(202, 212, 200, 0.125); /* Slightly darker background for highlights */
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  width: 100%;
  
  .income-increase {
    color: #28a745; /* Green for positive income */
  }

  .arrow-up {
    color: #28a745; /* Arrow in green color */
    margin-left: 5px; /* Add some space between the amount and the arrow */
    font-size: 15px;
    font-weight: bold;
  }
}


.referral-section {
  text-align: center;
  margin-bottom: 1rem;
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  background: #03dc4f; /* Green button */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    background: #472188; /* Darker green on hover */
    transform: scale(1.05);
  }
}

.progress-card {
  background: linear-gradient(135deg, #2c3e50, #34495e); /* Different shade for direct */
  border: 2px;
border-color: #fff;
  
  h3 {
    text-align: center;
    color: #3600fa;
  }

  .progress-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    
    .label {
      font-size: 14px;
      color: #f0efef;
    }
    
    .progress-bar {
      width: 70%;
      height: 10px;
      background: #eee;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      
      .filled-bar {
        height: 100%;
        background: #5700fa;
        border-radius: 5px;
      }
    }
  }

  .next-level-info {
    text-align: center;
    font-size: 14px;
    color: #5700fa;
  }
}

.profile-container {
  background: #101010;
  color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  font-family: 'Poppins', sans-serif;

  .profile-header {
    display: flex;
    align-items: center;
    gap: 15px;

    .profile-image {
      border-radius: 50%;
      overflow: hidden;
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .profile-info {
      flex-grow: 1;
      h4 {
        font-size: 1.2rem;
        margin: 0;
      }
      p {
        font-size: 0.9rem;
        color: #ccc;
      }
    }

    .edit-profile {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
      h5 {
        font-size: 0.85rem;
        margin: 0;
      }
    }
  }

  .referrer-details {
    margin-top: 15px;

    .referrer-info {
      p {
        font-size: 0.85rem;
        margin: 5px 0;
      }
    }

    .open-referrals-btn {
      margin-top: 10px;
      padding: 8px 12px;
      background: linear-gradient(90deg, #25b202, #8dff71);
      color: #fff;
      border: none;
      border-radius: 8px;
      font-size: 0.85rem;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: linear-gradient(90deg, #02ad27, #74ff5f);
      }
    }
  }
}
.profile-container {
  width: 100%;
  max-width: 48rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: #1a1b1f;
  color: white;

  .profile-header {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;

    .profile-image {
      position: relative;

      .avatar-wrapper {
        width: 6rem;
        height: 6rem;
        border-radius: 9999px;
        background: linear-gradient(to right, #9333ea, #3b82f6, #22c55e);
        padding: 2px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 9999px;
          object-fit: cover;
          background-color: #1f2937;
        }
      }

      .social-counter {
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        .counter-pill {
          padding: 0.25rem 1rem;
          border-radius: 9999px;
          background-color: #2c2d31;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .heart {
            color: #03dc4f;
          }
        }
      }
    }

    .profile-info {
      flex: 1;

      .id-section {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;

        h1 {
          font-size: 1.5rem;
          font-weight: 700;
        }

        .copy-button {
          color: #9ca3af;
          cursor: pointer;
          transition: color 0.2s;

          &:hover {
            color: white;
          }
        }
      }

      .address {
        color: #9ca3af;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }

      .invited-info {
        color: #9ca3af;
        font-size: 0.875rem;
      }
    }
  }

  .personal-link {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #2c2d31;

    .link-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.25rem;

      .label {
        color: #9ca3af;
      }

      .copy-button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        border-radius: 0.375rem;
        background-color: #4461f2;
        color: white;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #3451e2;
        }
      }
    }

    .link-text {
      color: #4461f2;
    }
  }

  .token-section {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: linear-gradient(
      to right,
      rgba(147, 51, 234, 0.1),
      rgba(59, 130, 246, 0.1),
      rgba(34, 197, 94, 0.1)
    );
    display: flex;
    align-items: center;
    justify-content: space-between;

    .token-info {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .token-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 9999px;
        background: linear-gradient(to right, #9333ea, #3b82f6, #22c55e);
      }

      .token-name {
        font-weight: 600;
      }
    }

    .login-button {
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      border: 1px solid rgba(34, 197, 94, 0.5);
      color: #22c55e;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba(34, 197, 94, 0.1);
      }
    }
  }
}

// Animation for copy feedback
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.copy-feedback {
  animation: fadeIn 0.2s ease-in, fadeOut 0.2s ease-out 1.8s;
}

.profile-container {
  background: #111;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 100%;
  max-width: 400px;
}

.profile-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #666;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-info {
  flex: 1;
  margin-left: 15px;
  h4 {
    font-size: 18px;
    margin: 0;
  }
}

.edit-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #aaa;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  h5 {
    margin: 0;
    font-size: 14px;
  }
  &:hover {
    color: #fff;
  }
}

.referrer-details {
  width: 100%;
  margin-top: 20px;
  background: #222;
  padding: 10px;
  border-radius: 8px;
  .referer-info {
    p {
      margin: 5px 0;
      font-size: 14px;
      color: #ccc;
    }
  }
}

.open-referrals-btn {
  background: linear-gradient(90deg, #6e40c9, #aa2b6c);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  &:hover {
    background: linear-gradient(90deg, #5c34b0, #92265f);
  }
}

.photo-update {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  .upload-btn {
    background: #444;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #666;
    }
  }
}
.home {
  place-items: center;
  padding: 6rem 0;
}

// Farm Component Styles
.farm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: rgba(1, 37, 16, 0.5); // Semi-transparent glass effect
  // backdrop-filter: blur(10px); // Glass blur effect
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(136, 231, 133, 0.6); // Subtle shadow for depth
  max-width: 1200px;
  margin: auto;
  
  .farm_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    hr.mb-32 {
      margin-bottom: 32px;
    }
    
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px; // Adjust as needed
    }
    
    .form_container {
      width: 100%;
      max-width: 800px; // Limit width for larger screens
      margin: 0 auto;
      
      .staking_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .staking_wrapper-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          
          h3 {
            font-size: 1.5rem;
            color: var(--text-primary);
          }
          
          button {
            background: transparent;
            border: none;
            font-size: 1rem;
            cursor: pointer;
            padding: 0.5rem;
            transition: background 200ms ease;

            &.active {
              background: var(--btn-primary);
              color: #fff;
              border-radius: 8px;
            }
          }
        }
        
        .staking_wrapper-card {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .staking_wrapper-card_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            background: rgba(0, 0, 0, 0.2); // Glass effect background
            // backdrop-filter: blur(8px); // Glass blur effect
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow
          }
        }
      }
      
      .pointer {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: color 200ms ease;

        &:hover {
          color: var(--btn-primary);
        }
      }
    }
    
    a {
      color: #ffcb63;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: color 200ms ease;

      &:hover {
        color: var(--btn-primary);
      }
    }
  }
}

// Responsive Styles
@media (max-width: 768px) {
  .farm {
    padding: 15px;

    .farm_content {
      .form_container {
        padding: 15px;

        .staking_wrapper-header {
          flex-direction: column;
          align-items: flex-start;
          
          button {
            margin: 0.5rem 0;
          }
        }

        .staking_wrapper-card {
          .staking_wrapper-card_content {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .farm {
    padding: 10px;
    align-items: center;
    justify-content: center;

    .farm_content {
      .form_container {
        padding: 10px;

        .staking_wrapper-header {
          h3 {
            font-size: 1.2rem;
          }
          
          button {
            font-size: 0.9rem;
            padding: 0.3rem;
          }
        }

        .staking_wrapper-card {
          border-radius: 5px;
          border-color: #fff;
          .staking_wrapper-card_content {
            font-size: 0.9rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}

// Deposit Component Styles

.premium-boxes {
  .title {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
    font-size: 1.8rem;
  }

  .package-grid {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .package-box {
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3); // Glass effect background
    // backdrop-filter: blur(10px); // Glass blur effect
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;

    h4 {
      margin-bottom: 1rem;
      font-size: 1.4rem;
      color: #ffc758;
    }

    p {
      margin-bottom: 1rem;
      font-size: 1rem;
      color: #fff;
    }

    button {
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        45deg,
        #089527 0%,
        #1e2328 50%,
        #56ad0a 100%
        
      );
      color: #fff;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #f8c84d;
      }
    }

    &.active {
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }

  .input-container {
    text-align: center;
    margin-bottom: 2rem;

    label {
      font-size: 1rem;
      color: #fff;
    }

    input {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      font-size: 1rem;
      margin-top: 0.5rem;
      align-items: center;
      justify-content: center;
    }

    .error {
      color: tomato;
      margin-top: 0.5rem;
    }
  }
}

// Responsive Styles
@media (max-width: 768px) {
  .premium-boxes {
    .title {
      font-size: 1.6rem;
    }

    .package-box {
      align-items: center;
      padding: 1rem;
justify-content: center;
      h4 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.9rem;
      }

      button {
        font-size: 14px;
        padding: 8px;
      }
    }
  }
}

@media (max-width: 576px) {
  .premium-boxes {
    align-items: center;
    justify-content: center;
    .title {
      font-size: 1.4rem;
    }

    .package-box {
      align-items: center;
      justify-content: center;
      padding: 0.8rem;

      h4 {
        font-size: 1rem;
      }

      p {
        font-size: 0.8rem;
      }

      button {
        font-size: 12px;
        padding: 6px;
      }
    }
  }
}

.farm_stats {
  padding: 1rem;
  background: rgba(0, 21, 66, 0);
  border: 1px solid #ffffff00;
  border-radius: 10px;
  margin-bottom: 2rem;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #1b1c1e;

    p {
      font-size: 1.6rem;
      color: #fff;
    }

    b {
      font-size: 1.8rem;
      color: #ffc247;
    }
  }

  @media (max-width: 768px) {
    padding: 0.8rem;

    .flex {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      p {
        font-size: 1.4rem;
      }

      b {
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 0.5rem;

    .flex {
      p,
      b {
        font-size: 1.4rem;
      }
    }
  }
}
.farm_header {
  padding: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  .farm_header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .token-info {
    display: flex;
    align-items: center;

    .token-logo {
      width: 48px;
      margin-right: 12px;
    }

    .token-title {
      font-size: 0.2rem;
      font-weight: bold;
      color: #fff;
    }
  }

  .balance-display {
    .balance-amount {
      font-size: 0.3rem;
      font-weight: bold;
      color: #fff;
    }
  }
}

// Glass effect styles
.glass-effect {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

// Staking.scss
.staking-container {
  background: rgba(255, 255, 255, 0.1);
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Roboto', sans-serif;

  h2 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;

    &:focus {
      border-color: #4caf50;
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .nifty-card-body {
    display: flex;
    flex-wrap: wrap; // Allow wrapping to next line
    gap: 10px;
    justify-content: center; // Align items in center
  }
  
  .card2 {
    width: calc(50% - 10px); // Ensure two cards per row
  }
  
  
  button {
    background: linear-gradient(45deg, #4caf50, #8bc34a);
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 10px;

    &:disabled {
      background: rgba(76, 175, 80, 0.5);
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: linear-gradient(45deg, #45a049, #7cb342);
    }
  }

  .stake-info {
    margin-top: 20px;
    color: #fff;
    p {
      font-size: 16px;
      margin: 5px 0;
    }

    p:nth-child(1) {
      font-weight: bold;
    }
  }

  .loading {
    display: inline-block;
    margin-top: 10px;
    padding: 5px;
    font-size: 16px;
    color: #8bc34a;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .staking-container {
    padding: 15px;
    max-width: 400px;
  }

  h2 {
    font-size: 20px;
  }

  input,
  button {
    font-size: 14px;
    padding: 8px;
  }

  .stake-info p {
    font-size: 14px;
  }
}
.staking-container {
  text-align: center;
  padding: 20px;
}

.staking-plans {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.staking-card {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  text-align: center;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  h3 {
    font-size: 20px;
    color: white;
  }

  p {
    font-size: 16px;
    color: #ddd;
  }

  button {
    background: #ff7b00;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;

    &:hover {
      background: #ff5400;
    }
  }
}
.referral-input {
  margin: 15px 0;
  label {
    display: block;
    font-size: 14px;
    color: white;
  }
  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
}

.referral-info {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-size: 14px;
}

.nft-card {
    display: flex;
    flex-direction: column;
    background: rgba(4, 155, 4, 0);
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(3, 216, 39, 0.406);
    overflow: hidden;
    width: 250px;
    margin: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    &__image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  
    &__content {
      padding: 15px;
    }
  
    &__title {
      font-size: 1.2em;
      font-weight: bold;
      color: #333;
    }
  
    &__description {
      font-size: 0.9em;
      color: #777;
      margin: 10px 0;
    }
  
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  
    &__price {
      font-size: 1.1em;
      font-weight: bold;
      color: #ffffff;
    }
  
    &__buy-button {
      padding: 8px 15px;
      background-color: #40ff00;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #000000;
      }
    }
  }
  .nft-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin-top: 40px;
  }
  